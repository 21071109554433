import React from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const AboutUsPage = ({data}) => {
return <Layout>
            <Helmet>
                <title>About Us</title>
                <meta name="description" content="We help you seek God, be it through Puja, Homa, Yagna, Havana, Darshana and we make sure that you have the best of the Experiences. We use technology to provide you the Godly experiences. Believe us you will love it." />
                <script src='https://kit.fontawesome.com/a076d05399.js'></script>
            </Helmet>
            <div id="main" className="about-us">
                <section id="one">
                    <div className="inner">
                        <div className="image">
                            <img src={data.allFile.nodes[0].childImageSharp.fluid.src} alt="About NamasteGod" title="About NamasteGod"/>
                        </div>
                        <div className="set">
                            <center><i className="fas fa-praying-hands"></i></center>
                            <h3>Faith</h3>
                            <p>God is working things out for you.<br/>Have faith and be thankful.</p>
                        </div>
                        <div className="set">
                            <center><i className="fas fa-dove"></i></center>
                            <h3>Peace</h3>
                            <p>As we pour out our bitterness.<br/>God pours in his peace.</p>
                        </div>
                        <div className="set">
                            <center><i className="fas fa-smile"></i></center>
                            <h3>Happiness</h3>
                            <p>Happiness is in the heart and<br/>God makes you feel so.</p>
                        </div>
                        <p>We help you seek God, be it through Puja, Homa, Yagna, Havana, Darshana and we make sure that you have the best of the Experiences. We use technology to provide you the Godly experiences. Believe us, you will <i className="fa fa-heart" aria-hidden="true"></i> it.</p>
                    </div>
                </section>
            </div>
        </Layout>
}

export const query = graphql`
{
  allFile(filter: {name: {eq:"God-loves-each-of-us-as-if-there-were-only-one-of-us"}}) {
    nodes {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
}`

export default AboutUsPage
